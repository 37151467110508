<template>
  <div class="home">
    <div class="hero">
      <div class="container">
        <div class="row text-white justify-content-center justify-content-sm-start">
          <div class="col-12 col-sm-7 col-md-6 col-lg-5">
            <h1 class="hero__title">
              Instant Help with
              All&nbsp;Types of Math
            </h1>
            <p class="hero__text">
              Don’t waste your time! Choose MathMaster and get answers to your math problems in a
              snap.
            </p>
            <div class="d-flex flex-column align-items-stretch align-items-sm-start">
              <router-link to="/tutorial" class="btn btn-primary site-btn site-btn_primary"
                           @click.native="$store.dispatch('clickHouseEvent', 'firstpage_click')"
              >
                Get Started
              </router-link>
            </div>
          </div>
        </div>
        <div class="hero-steps
        d-flex flex-column align-items-stretch align-items-lg-center flex-lg-row">
          <div class="step d-flex flex-grow-1">
            <img class="step__img" src="../assets/task.png" alt="">
            <div class="step-info">
              <p class="step__num m-0">
                step 1
              </p>
              <p class="step__title m-0">
                Upload or take a photo
              </p>
            </div>
          </div>
          <div class="step d-flex flex-grow-1">
            <img class="step__img" src="../assets/chat.png" alt="">
            <div class="step-info">
              <p class="step__num m-0">
                step 2
              </p>
              <p class="step__title m-0">
                Start a chat to get the&nbsp;answer
              </p>
            </div>
          </div>
          <div class="step d-flex flex-grow-1 align-items-center">
            <img class="step__img" src="../assets/solution.png" alt="">
            <div class="step-info">
              <p class="step__num m-0">
                step 3
              </p>
              <p class="step__title m-0">
                Get step-by-step solution
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="choose">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7 d-flex flex-column align-items-center">
            <h2 class="choose__title text-center">
              Why Choose MathMaster for your
              Math&nbsp;Homework&nbsp;help?
            </h2>
            <p class="choose__text text-center">
              Our math experts provide step-by-step solutions to help students understand
              complex math problems easier and prepare for exams and tests better.
            </p>
            <div class="choose-comment w-100">
              <p class="choose-comment__text text-center m-0">
                “Getting math help is simpler than you think!”
              </p>
            </div>
            <div class="d-flex flex-column align-items-stretch align-items-md-start">
              <router-link to="/tutorial" class="btn btn-primary site-btn site-btn_primary"
                           @click.native="$store.dispatch('clickHouseEvent', 'firstpage_click')"
              >
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone">
      <div class="container">
        <img srcset="../assets/expertchat-563.png 563w,
                     ../assets/expertchat-1665.png 1665w"
             sizes="(max-width: 500px) 563px,
                     1665px"
             class="w-100" alt="">
      </div>
    </div>
    <div class="levels">
      <div class="container">
        <div class="row justify-content-md-center justify-content-lg-between">
          <div class="col-12 col-md-10 col-lg-5">
            <h2 class="levels__title text-center text-lg-end">
              We Cover All Levels of Math
            </h2>
            <p class="levels__text text-center text-lg-end">
              MathMaster helps you with any type of math you are studying. Never be outnumbered with
              your math homework again.
            </p>
          </div>
          <div class="col-12 col-md-8 col-lg-6">
            <div class="levels-list row align-items-center">
              <div class="col-6">
                <div class="levels-list__item">
                  Basic Math
                </div>
              </div>
              <div class="col-6">
                <div class="levels-list__item">
                  Trigonometry
                </div>
              </div>
              <div class="col-6">
                <div class="levels-list__item">
                  Pre-Algebra
                </div>
              </div>
              <div class="col-6">
                <div class="levels-list__item">
                  Precalculus / Calculus
                </div>
              </div>
              <div class="col-6">
                <div class="levels-list__item">
                  Algebra
                </div>
              </div>
              <div class="col-6">
                <div class="levels-list__item">
                  Statistics
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="levels-formulas position-relative">
          <img src="../assets/formulas-563.png"
               srcset="../assets/formulas-563.png 563w,
                     ../assets/formulas-1665.png 1665w"
               sizes="(max-width: 500px) 563px,
                     1665px"
               class="w-100" alt="">
          <div class="d-flex flex-column align-items-stretch align-items-md-start">
            <router-link to="/tutorial" class="btn btn-primary site-btn site-btn_primary
            levels-formulas__btn"
                         @click.native="$store.dispatch('clickHouseEvent', 'firstpage_click')"
            >
              Get Started
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="experts text-white" v-if="$store.state.cloaking === 'false'">
      <div class="container">
        <h2 class="experts__title text-center m-2 mb-md-3 mb-lg-0">
          Meet our experts.
        </h2>
        <h3 class="experts__subtitle text-center">
          Get accurate math solutions you can trust
        </h3>
        <div class="row">
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/john.png" alt="">
              <p class="expert__name">
                John Reed
              </p>
              <p class="expert__position m-0 text-center">
                University of Lethbridge
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/chatelle.png" alt="">
              <p class="expert__name">
                Chantelle Hanratty
              </p>
              <p class="expert__position m-0 text-center">
                University of Alberta
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/nadia.png" alt="">
              <p class="expert__name">
                Nadia Moore
              </p>
              <p class="expert__position m-0 text-center">
                Pretoria Technical High School
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/catherine.png" alt="">
              <p class="expert__name">
                Catherine Graham
              </p>
              <p class="expert__position m-0 text-center">
                University of Chicago
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/aaron.png" alt="">
              <p class="expert__name">
                Aaron Marcus
              </p>
              <p class="expert__position m-0 text-center">
                Queen's University
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/jacob.png" alt="">
              <p class="expert__name">
                Jacob Furlan
              </p>
              <p class="expert__position m-0 text-center">
                Cross High School
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/gertrude.png" alt="">
              <p class="expert__name">
                Gertrude Davidson
              </p>
              <p class="expert__position m-0 text-center">
                University of Lethbridge
              </p>
            </div>
          </div>
          <div class="col-6 ms-sm-auto ms-lg-0 col-sm-4 col-lg-3">
            <div class="expert d-flex flex-column align-items-center">
              <img class="expert__avatar" src="../assets/avatars/stephen.png" alt="">
              <p class="expert__name">
                Stephen Mulware
              </p>
              <p class="expert__position m-0 text-center">
                Pretoria Technical High School
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <router-link to="/tutorial" class="btn btn-primary site-btn site-btn_white">
            Ask an Expert
          </router-link>
        </div>
      </div>
    </div>
    <div class="result text-white">
      <div class="result-main">
        <div class="container">
          <div class="row justify-content-md-center justify-content-lg-end">
            <div class="col-12 col-md-12 col-lg-6">
              <h2 class="result__title">
                Maximum Results. Minimum Time.
              </h2>
              <p class="result__text">
                Don’t waste your time!
                Choose MathMaster and get answers to your math problems instantly.
              </p>
              <div class="d-lg-none row justify-content-center pb-3 mb-3">
                <div class="col-sm-9 col-md-6">
                  <img src="../assets/tablet_and_phone.png" alt="" class="w-100">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="result-block">
                    <h3 class="result-block__title">
                      Over 16 K
                    </h3>
                    <h4 class="result-block__subtitle">
                      Solved Tasks
                    </h4>
                    <p class="result-block__text">
                      Get step-by-step solutions with a detailed explanation of each step.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="result-block">
                    <h3 class="result-block__title">
                      80 +
                    </h3>
                    <h4 class="result-block__subtitle">
                      Pro Experts
                    </h4>
                    <p class="result-block__text">
                      One-to-one online tuition that raises grades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center">
        <router-link to="/tutorial" class="btn btn-primary site-btn site-btn_white"
                     @click.native="$store.dispatch('clickHouseEvent', 'firstpage_click')"
        >
          Get Started
        </router-link>
      </div>
    </div>
    <template v-if="$store.state.cloaking === 'false'">
      <div class="brands">
        <div class="container">
          <img class="w-100" src="../assets/brands.png" alt="">
        </div>
      </div>
      <div class="comments">
        <div class="comments-slider">
          <div class="container slider-container">
            <div class="row justify-content-center">
              <div class="col-10">
                <Swiper ref="mySwiper" :options="swiperOption">
                  <SwiperSlide v-for="comment in comments" :key="comment.id">
                    <div class="slide d-flex flex-column align-items-center">
                      <p class="slide__text text-black text-center">
                        {{ comment.text }}
                      </p>
                      <img :src="comment.author.photo" alt="" class="slide__image">
                      <p class="slide__name text-black">
                        {{ comment.author.name }}
                      </p>
                      <p class="slide__position">
                        {{ comment.author.position }}
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="faq">
      <div class="container">
        <h2 class="faq__title text-center">
          Customers ask, we answer
        </h2>
        <div class="faq-container row justify-content-center">
          <div class="col-12 col-md-8 col-lg-6">
            <div class="faq-block d-flex flex-column align-items-center"
                 v-for="answer of answers"
                 :key="answer.id"
            >
              <h4 class="faq-block__title d-flex align-items-center"
                  data-bs-toggle="collapse"
                  :href="'#' + answer.id + '_text'"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
              >
                {{ answer.title }}
                <img src="../assets/chevron_down.svg" alt="" class="ms-2">
              </h4>
              <div class="collapse pt-2" :id="answer.id + '_text'">
                <div class="card card-body">
                  <p class="m-0" v-html="answer.text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

const avatars = [
  // eslint-disable-next-line global-require
  require('../assets/avatars/albert.jpg'),
  // eslint-disable-next-line global-require
  require('../assets/avatars/suzy.jpg'),
  // eslint-disable-next-line global-require
  require('../assets/avatars/adolph.jpg'),
];

export default {
  name: 'Home',
  components: {
    SwiperSlide,
    Swiper,
  },
  data() {
    return {
      comments: [
        {
          id: 0,
          text: '“Like having a math tutor, 24/7. Well done to the creators of this service. No matter the exam, score improvement is guaranteed.”',
          author: {
            name: 'Albert F.',
            position: 'Student',
            photo: avatars[0],
          },
        },
        {
          id: 2,
          text: '“My daughter has always struggled with math throughout grade school and in to JH. We decided to give MathMaster a try to see if we could improve her grades and her confidence for her freshman year. I can\'t tell you what an amazing experience it has been!”',
          author: {
            name: 'Suzy B.',
            position: 'Mom to 9th Grader Mary',
            photo: avatars[1],
          },
        },
        {
          id: 3,
          text: '“I use this app mostly to check my work and see exactly where I went wrong in solving. I use this on all of my homework and practice notes. Five out five stars, hands down.”',
          author: {
            name: 'Adolph H.',
            position: 'Student',
            photo: avatars[2],
          },
        },
      ],
      answers: [
        {
          id: 'one',
          title: 'What are Experts\' certifications and backgrounds?',
          text: 'Our experts are experienced professors and tutors from all over the world with strong skills in teaching math and solving math problems of a wide range of topics.',
        },
        {
          id: 'two',
          title: 'How quickly will I get help with my math homework?',
          text: 'We will do our best to provide you with math solutions as quickly as possible. You will receive help with your math homework within a few moments.',
        },
        {
          id: 'tree',
          title: 'Who do I contact if I have a question or concern?',
          text: 'If you have any questions or concerns, feel free to contact our support team at <a class="d-inline" href="mailto:support@mathmaster.me"> support@mathmaster.me</a>.',
        },
      ],
      offsetLeft: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
      },
      activeSlide: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.$store.dispatch('clickHouseEvent', 'firstpage_view');
  }
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url('../assets/base.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 0;

  &__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 40px;
  }

  &-steps {
    margin-top: 140px;
    background: #FFFFFF;
    border-radius: 32px;
  }
}

.step {
  padding: 30px;

  &__img {
    margin-right: 20px;
    width: 125px;
  }

  &__num {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #5E5CE6;
    mix-blend-mode: normal;
    opacity: 0.5;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.4px;
    font-weight: bold;
  }
}

.choose {
  background-image: url('../assets/BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 80px 0 56px;

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.8px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }

  &-comment {
    border-radius: 24px;
    background: #E5E5E5;
    padding: 48px 24px;
    margin-bottom: 40px;

    &__text {
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: -0.8px;
    }
  }
}

.levels {
  padding: 80px 0 0;

  &__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 54px;
  }

  &-list {
    &__item {
      position: relative;
      font-size: 21px;
      line-height: 53px;
      letter-spacing: -0.481091px;
      margin-bottom: 16px;
      padding-left: 38px;

      &:before {
        content: '';
        background-image: url("../assets/a+.svg");
        width: 24px;
        height: 24px;
        background-position: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &-formulas {
    &__btn {
      bottom: 80px;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      z-index: 1;
    }
  }
}

.experts {
  background: #5E5CE6;
  padding: 80px 0 0;

  &__title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 76px;
  }

  &__subtitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 68px;
    margin-bottom: 56px;
  }
}

.expert {
  margin-bottom: 56px;

  &__avatar {
    border-radius: 32px;
    margin-bottom: 32px;
  }

  &__name {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }

  &__position {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
}

.result {
  background: #5E5CE6;
  padding-bottom: 80px;

  &-main {
    padding: 160px 0 110px;
    background-image: url('../assets/result_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 40px;
  }

  &__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 80px;
  }

  &-block {
    &__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      letter-spacing: -1.6px;
      margin-bottom: 8px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.4px;
      margin-bottom: 0;
    }
  }
}

.brands {
  padding: 80px 0;
}

.comments {
  overflow: hidden;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.swiper-slide, {
  opacity: 0.4;
}

.swiper-container {
  overflow: visible !important;
}

.slide {

  &__text {
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1.6px;
    margin-bottom: 40px;
  }

  &__image {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-bottom: 24px;
  }

  &__name {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }

  &__position {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #594D6D;
    margin-bottom: 0;
  }
}

.faq {
  padding: 80px 0;

  &__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -2px;
    margin-bottom: 40px;
  }

  &-block {
    margin-bottom: 24px;

    &__title {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.4px;
      margin-bottom: 0;
    }

    &__text {

    }
  }
}

@media screen and (min-width: 1440px) {
  .choose {
    background-size: contain;
    background-image: url('../assets/BG_1440.png');
  }
  .result {
    &-main {
      background-image: url('../assets/result_bg_1440.png');
      background-size: contain;
    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    background-position: center top;
    background-image: url('../assets/base_lap.png');
    background-size: contain;
    background-color: #313079;

    &-steps {
      margin-top: 56px;
      background-color: unset;
    }

    .step {
      background-color: rgba(255, 255, 255, 0.2);
      color: #FFFFFF;
      border-radius: 18px;
      margin-bottom: 16px;
      padding: 10px;

      &__num {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .result {
    padding-bottom: 32px;

    &-main {
      padding-top: 96px;
      background-image: none;
      padding-bottom: 0;
      margin-bottom: 32px;
    }

    &__title {
      font-size: 36px;
      line-height: 44px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 32px;
    }

    &-block {
      &__title {
        font-size: 24px;
        line-height: 34px;
      }

      &__subtitle {
        font-size: 16px;
        line-height: 24px;
      }

      &__text {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .experts {
    padding: 48px 0 0;

    &__title {
      font-size: 36px;
      line-height: 55px;
    }

    &__subtitle {
      padding: 0 10px;
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 32px;
    }
  }

  .expert {
    margin-bottom: 32px;

    &__avatar {
      width: 80px;
      margin-bottom: 24px;
    }

    &__name {
      font-size: 16px;
      line-height: 22px;
    }

    &__position {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .slide {
    &__text {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 48px;
    }

    &__image {
      width: 48px;
      height: 48px;
    }

    &__name {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 0;
    }

    &__position {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .swiper-slide {
    padding: 48px 16px;
    border: 1px solid #E7E5EA;
    box-sizing: border-box;
    border-radius: 16px;
  }
}

@media screen and (max-width: 576px) {
  .hero {
    padding: 218px 0 20px;
    background-image: url('../assets/base-m.png');

    &__title {
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 32px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .choose {
    padding: 48px 0;
    background-image: none;

    &__title {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 32px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
    }

    &-comment {
      padding: 32px;
    }
  }

  .levels {
    padding: 48px 0 0;

    &__title {
      font-size: 36px;
      line-height: 45px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 32px;
    }

    &-list {
      &__item {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }

    &-formulas__btn {
      bottom: 40px;
    }
  }

  .result {
    padding-bottom: 32px;

    &-main {
      padding-top: 96px;
      background-image: none;
      padding-bottom: 0;
      margin-bottom: 32px;
    }

    &__title {
      font-size: 36px;
      line-height: 44px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 32px;
    }

    &-block {
      &__title {
        font-size: 24px;
        line-height: 34px;
      }

      &__subtitle {
        font-size: 16px;
        line-height: 24px;
      }

      &__text {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .brands {
    padding: 24px 0;
  }

  .comments {
    padding: 48px 0 0;
  }

  .faq {
    padding: 40px;

    &__title {
      font-size: 36px;
      line-height: 48px;
    }
  }
}

</style>
