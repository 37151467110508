<template>
  <div class="header">
    <div class="container">
      <div class="row justify-content-end justify-content-sm-start">
        <div class="header-buttons d-none d-sm-flex align-items-center col-sm-4">
          <router-link
            to="/privacy"
            active-class=""
            class="header-buttons__link text-decoration-none"
          >
            Privacy
          </router-link>
          <router-link
            to="/terms"
            class="header-buttons__link text-decoration-none ms-4 ms-xl-5"
          >
            Terms
          </router-link>
        </div>
        <router-link
          to="/"
          class="header-logo col col-sm-5 col-md-4 justify-content-center text-decoration-none">
          <img class="header-logo__img" src="../../assets/Logo.svg" alt="">
          <span class="header-logo__text">
          MathMaster
        </span>
        </router-link>
        <div class="col-auto d-flex d-sm-none d-flex align-items-center">
          <a href="#" class="header-menu p-2">
            <img src="../../assets/Menu.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
.header {
  background: #5E5CE6;
  padding: 16px 0;

  &-buttons {
    &__link {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.355556px;
      color: white;

      &_active {
        color: #bdbdbd;
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;

    &__img {
      margin-right: 10px;
      width: 40px;
      height: 40px;
    }

    &__text {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #F3F4F8;
    }
  }
}
</style>
