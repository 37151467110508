import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    credentials: {},
    user_uid: '',
    event_id: document.querySelector('#eventId').innerText.trim().includes('{{')
      ? '35391962'
      : document.querySelector('#eventId').innerText.trim(),
    landing_uuid: document.querySelector('#landing_uuid').innerText.trim().includes('{{')
      ? 'd7aab0d3-db92-4845-a9c0-7546827d60bc'
      : document.querySelector('#landing_uuid').innerText.trim(),
    cloaking: (() => {
      const cloak = document.querySelector('#cloaking').innerHTML.trim();
      document.querySelector('#cloaking').remove();
      return cloak.includes('if') ? 'true' : cloak;
    })(),
  },
  mutations: {
    setCredentials(state, value) {
      state.credentials = value;
    },
    setUserUid(state, value) {
      state.user_uid = value;
    },
  },
  actions: {
    clickHouseEvent(context, event) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      formData.append('event_type', event);
      return fetch('https://web2app.fx2.io/event/store',
        {
          method: 'POST',
          body: formData,
        });
    },
    getProducts(context) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      return fetch('https://web2app.fx2.io/payment/form/products',
        {
          method: 'POST',
          body: formData,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
    },
    getCredentials(context, { login, password }) {
      const formData = new FormData();
      formData.append('email', login);
      formData.append('password', password);
      return fetch('https://expertchat.mathmaster.me/api/user/auth', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    checkTransactionStatus(context, orderUuid) {
      const formData = new FormData();
      formData.append('order_uuid', orderUuid);
      return fetch('https://web2app.fx2.io/order/status', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
  },
  modules: {},
});
