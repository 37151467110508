<template>
<div class="footer">
  <div class="container
  d-flex flex-column flex-md-row align-items-md-center justify-content-between">
    <p class="footer__copy">
      © 2021 Starnest Services Ltd, Limassol, Cyprus. All rights reserved.
    </p>
    <div class="d-flex align-items-center mt-4 mb-3 mt-md-0 mb-md-0 justify-content-around">
      <router-link to="/privacy" class="footer-buttons__link text-decoration-none mx-2">
        Privacy policy
      </router-link>
      <router-link to="/terms" class="footer-buttons__link text-decoration-none mx-2">
        Terms and conditions
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer {
  background: #5E5CE6;
  padding: 24px 42px;

  &__copy {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin: 0;
  }

  &-buttons {
    &__link {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.355556px;
      color: white;

      &_active {
        color: #bdbdbd;
      }
    }
  }
}
</style>
