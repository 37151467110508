<template>
  <div id="app">
    <Header v-if="mainPage"></Header>
    <router-view/>
    <Footer v-if="mainPage"></Footer>
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      mainPage: true,
    };
  },
  methods: {
    isMainPage() {
      return /(^\/main)|(\/privacy)|(\/terms)/.test(this.$router.currentRoute.path);
    },
  },
  created() {
    this.mainPage = this.isMainPage();
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.mainPage = this.isMainPage();
      },
    },
  },
};
</script>

<style lang="scss">
</style>
