import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   route level code-splitting
  //   this generates a separate chunk (about.[hash].js) for this route
  //   which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  {
    path: '/main',
    name: 'Home',
    component: Home,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "other" */ '../views/Privacy.vue'),
  },
  {
    path: '/money-back',
    name: 'Money Back',
    component: () => import(/* webpackChunkName: "other" */ '../views/MoneyBack.vue'),
  },
  {
    path: '/sub-terms',
    name: 'Subscription terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/SubTerms.vue'),
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import(/* webpackChunkName: "proc" */ '../views/Tutorial.vue'),
  },
  {
    path: '/mail',
    name: 'Mail',
    component: () => import(/* webpackChunkName: "proc" */ '../views/Mail.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Success.vue'),
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans_v2.vue'),
  },
  {
    path: '*',
    redirect: '/main',
  },
];

const router = new VueRouter({
  routes,
});

export default router;
