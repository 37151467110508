import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { BModal, BSpinner } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles/main.scss';

import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.component('b-modal', BModal);
Vue.component('b-spinner', BSpinner);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export function setCookie(name, value, options = {}) {
  const newOptions = {
    path: '/',
    ...options,
  };
  if (newOptions.expires instanceof Date) {
    newOptions.expires = newOptions.expires.toUTCString();
  }
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const optionKey in newOptions) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = newOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  const regex = new RegExp('([\\.$?*|{}\\(\\)\\[\\]\\\\\\/\\+^])', 'g');
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(regex, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

function initFbp() {
  const fbp = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!fbp) {
    setTimeout(initFbp, 1000);
  } else if (fbp[1]) {
    const formData = new FormData();
    formData.append('event_id', document.querySelector('#eventId')
      .innerText
      .trim());
    formData.append('event_type', 'fbp');
    formData.append('fbp', fbp[1]);
    fetch('https://web2app.fx2.io/event/store',
      {
        method: 'POST',
        body: formData,
      });
  }
}

initFbp();
